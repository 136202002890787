import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Config from '../../Config';
import {Link} from 'react-router-dom';
import LibraryMusic from '@material-ui/icons/LibraryMusic';
import AlbumIcon from '@material-ui/icons/Album';
import OndemandVideo from '@material-ui/icons/OndemandVideo';
import PropTypes from "prop-types";
import {getIdbySegment} from "../../redux/authActions";
import Typography from "@material-ui/core/Typography";
import Grammer from "../../Util/Grammer";

class Homepage extends Component {
 constructor(props) {
  super(props);
  this.state = (window.innerWidth > 576) ? {displaymode: 'block'} : {displaymode: 'background'};
 }

 showVideos(e) {
  this.setState({displaymode:'block'});
  const tdata = getIdbySegment(document.location.pathname);
  tdata.page = 'demos';
  tdata.value = 'default';
  window.logUse.logEvent('view', tdata);
 }

 render() {

  let dims =  {w: 375, h: 812}; // iphone X
  dims.h = Math.min(812, Math.floor(window.innerHeight / 1.2));
  let ratio =  dims.h / 812;
  dims.w = Math.floor(dims.w * ratio);
  let vidPath = '/videos/ftb/mobile/custom/ftb.mp4';

  return (
   <Grid container direction="row" justifyContent="center" alignItems="flex-start" style={{marginTop:60}}>
    <Grid container direction='column' alignItems='center' justifyContent='space-around' item xs={12} sm={7} spacing={8} >
     <img style={{
      width: '80%',
      maxWidth: 300,
      margin: '0 auto'
     }}
     src='https://api.trackauthoritymusic.com/sites/default/files/groups/covers/ftb-logo_2.png'
     alt="logo"/>


     <Grid item>
      <Typography variant='h1' style={{margin: 0}}>
       <Link to='/group/9' style={{textDecoration: 'none'}}>
        {Config.name}
       </Link>
      </Typography>
      <h3 style={{margin: 0, fontStyle: 'italic'}}>DJ Leagues</h3>
     </Grid>
     <Grid item style={{textAlign:'center', marginBottom:20}}>
      <Link to={'/group/9/playlists'} style={{textDecoration: 'none'}}>
       <Button aria-label='View Playlists' startIcon={<LibraryMusic/>} color='primary'
        variant='contained' style={{margin: 5}}>Search {Grammer.g('Playlists', 'p')}</Button>
      </Link>
      <Link to={'/form/group/9/playlists/add'} style={{textDecoration: 'none'}}>
       <Button aria-label='Create A Playlists' startIcon={<AlbumIcon/>} color='primary'
        variant='contained' style={{margin: 5}}>Add A {Grammer.g('Playlists', 's')}</Button>
      </Link>
      {this.state.displaymode === 'background' ?
       <Button aria-label='View Demos' startIcon={<OndemandVideo/>} color='primary'
        onClick={e => this.showVideos(e)}
        variant='contained' style={{margin: 5}}>View Demos</Button> : <></>
      }

     </Grid>
    </Grid>
    <Grid item xs={12} sm={5} >
     <div className="mobile" style={{width:dims.w, height:dims.h, marginBottom:20}}>
      <div className="deviceMask" >
       <video
        style={{objectFit:'cover'}}
        width={'100%'} height={'100%'}
        autoPlay muted playsInline
        src={'https://trackauthoritymusic.com'+vidPath} type="video/mp4"
       />
      </div>
     </div>
    </Grid>
   </Grid>
  );
 }
}

Homepage.defaultProps = {
 windowWidth: window.innerWidth,
 windowHeight: window.innerHeight,
};

Homepage.propTypes = {
 windowHeight: PropTypes.number,
 windowWidth: PropTypes.number
};

export default Homepage;
